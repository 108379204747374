import React from "react";

import styles from "../styles/sharedStyles.module.css";

import LogoFooter from "../source/GPHIDRO LOGO SVG2.svg"

function Footer() {
    return (
        <div
            className={`${styles.container} ${styles.footer_app}`}
        >
            <img
                className={styles.footer_img}
                src={LogoFooter}
                alt="LogoFooter"
            />
        </div>
    )
}

export default Footer;
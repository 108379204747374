import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../styles/sharedStyles.module.css";

import ConstantFile from "./constantFile";

function Calculator() {
    const [body, setBody] = useState({});
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState(null);
    const [calc, setCalc] = useState(false);
    const [middleLevel, setMiddleLevel] = useState(0);

    const changeMiddleLevel = (event) => {
        const value = event.target.value;

        setMiddleLevel(parseFloat(value));
    }

    useEffect(() => {
        const fetchData = async () => {

            body.NMML = middleLevel;

            try {
                const response = await axios.post("https://tide-editor-api.gphidro.com.br/nr", body);
                setResponseData(response.data);
            } catch (error) {
                setError("Erro ao consultar a rota");
                console.log("Erro ao consultar a rota:", error);
            }
        };

        fetchData();
    }, [calc]);

    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            <div className={styles.form}>
                <label className={styles.label}>
                    Nível Médio do Mar Local (NMML):
                    <input
                        name="nivel"
                        type="number"
                        className={styles.input}
                        value={middleLevel}
                        onChange={(event) => changeMiddleLevel(event)}
                    />
                </label>

                <label className={styles.label}>
                    Arquivo das Constantes Harmônicas:
                    <ConstantFile setBody={setBody} />
                </label>

                <button onClick={() => { setCalc(!calc) }}>Calcular</button>
            </div>

            <div className={styles.results}>
                <p>Resultados:</p>
                {/* {error && <p>{error}</p>} */}
                {responseData ? (
                    <div className={styles['results-box']}>
                        {
                            responseData?.C &&
                            responseData?.resultado?.classificacaoCriterioCourtier &&
                            responseData?.resultado?.Zo &&
                            (
                                <ul className={styles.ul}>
                                    Critério Courtier:
                                    <li className={styles.li}>C = {responseData?.C}</li>
                                    <li className={styles.li}>Classificação Maregráfica = {responseData?.resultado?.classificacaoCriterioCourtier} </li>
                                    {
                                        !(responseData.C > 0.25 && responseData.C <= 1.5) &&
                                        (<li className={styles.li}>Z0 = {responseData?.resultado?.Zo}</li>)
                                    }
                                </ul>
                            )
                        }
                        {
                            responseData?.resultado?.["2K"] &&
                            responseData?.resultado?.classificacaoBALAY &&
                            responseData?.resultado?.Zo &&
                            (
                                <ul className={styles.ul}>
                                    Método de Balay:
                                    <li className={styles.li}>2K = {responseData?.resultado?.["2K"]}</li>
                                    <li className={styles.li}>Classificação Maregráfica = {responseData?.resultado?.classificacaoBALAY}</li>
                                    {
                                        !(responseData.C <= 0.25 && responseData.C > 1.5) &&
                                        (<li className={styles.li}>Z0 = {responseData?.resultado?.Zo}</li>)
                                    }
                                </ul>
                            )
                        }
                        {
                            responseData?.NR &&
                            (
                                <ul className={styles.ul}>
                                    Nível de Redução seguro:
                                    <li className={styles.li}>NR = {responseData?.NR}</li>
                                </ul>
                            )
                        }
                    </div>
                ) : (
                    <p>Carregando...</p>
                )}
            </div>
        </div>
    );
}

export default Calculator;

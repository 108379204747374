import React, {
    useState,
    useEffect,
    useContext,
    useRef
} from "react";

import styles from "../styles/sharedStyles.module.css"

import {
    VictoryChart,
    VictoryLine,
    VictoryZoomContainer,
    VictoryTheme,
    VictoryAxis
} from 'victory';

import { SharedStateContext } from "../contexts/SharedStateContext";

import { parseInterval } from "../helpers/util";

function GraphicMarePrevista() {
    /********** useState **********/
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [tickCount, setTickCount] = useState(5);
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 500 });
    const [dataLines, setDataLines] = useState([]);

    /********** useContext **********/
    const {
        dataSetMarePrevista,
        setDataSetMarePrevista,
        textMarePrevista,
        generateGraphMarePrevista,
        headerEraseMarePrevista,
        columnsMarePrevista,
        separatorsMarePrevista,
        optionsMarePrevista,
        setHeaderLinesMarePrevista
    } = useContext(SharedStateContext);

    /********** useRef **********/
    const scrollRef = useRef(null);

    /********** Funções **********/
    const mountDataset = () => {
        const dataSetAux = [];
        let maxValueAux = 0;

        let regex;

        if (separatorsMarePrevista.length > 0) {
            regex = new RegExp(separatorsMarePrevista.join('|'), 'g');
        } else {
            regex = /\s|\n|\r/g;
        }

        let currentDate = null;
        if (optionsMarePrevista && optionsMarePrevista.data_inicial && optionsMarePrevista.hora_inicial) {
            currentDate = new Date(`${optionsMarePrevista.data_inicial}T${optionsMarePrevista.hora_inicial}`);
        } else {
            currentDate = new Date();
        }

        for (let i = 0; i < dataLines.length; i++) {
            let result = dataLines[i].split(regex).filter(Boolean);

            if (result.length >= columnsMarePrevista.length) {
                let date = '';
                let value = '';

                for (let j = 0; j < columnsMarePrevista.length; j++) {
                    if (columnsMarePrevista[j] === 'Profundidade') {
                        value = parseFloat(result[j]);
                    } else {
                        date += result[j] + ' ';
                    }
                }

                if (columnsMarePrevista.length === 1 && columnsMarePrevista[0] === 'Profundidade') {
                    for (let j = 0; j < result.length; j++) {
                        let value = parseFloat(result[j]);
                        let date = new Date(currentDate).toLocaleString();

                        const d = { index: i, date, value };

                        if (maxValueAux < d.value) {
                            maxValueAux = d.value;
                        }

                        dataSetAux.push(d);

                        if (optionsMarePrevista && optionsMarePrevista.intervalo) {
                            currentDate.setTime(currentDate.getTime() + parseInterval(optionsMarePrevista.intervalo));
                        } else {
                            currentDate.setTime(currentDate.getTime() + parseInterval("01:00:00"));
                        }
                    }
                }
                else {
                    const d = { index: i, date, value };

                    if (maxValueAux < d.value) {
                        maxValueAux = d.value;
                    }

                    dataSetAux.push(d);
                }
            }
        }

        setDataSetMarePrevista(dataSetAux);

    }

    const handleScroll = () => {
        const scrollPosition = scrollRef.current.scrollLeft;
        const maxScroll = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
        const dataLength = dataSetMarePrevista.length;
        const visibleDataCount = 500;

        // Calcula o índice de início de forma completamente fluida
        let start = Math.floor((scrollPosition / maxScroll) * (dataLength - visibleDataCount));
        if (start > dataLength - visibleDataCount) {
            start = dataLength - visibleDataCount; // Garante que não ultrapasse o limite dos dados
        }
        let end = start + visibleDataCount;

        setVisibleRange({ start, end });
    };

    const domainChange = (domain) => {
        //verifica a largura do domínio do eixo x
        const [xMin, xMax] = domain.x;
        const xWidth = Math.abs(xMax - xMin);

        // Atualiza o número de ticks do eixo x com base na largura do domínio
        if (xWidth < 2) {
            setTickCount(5);
        } else if (xWidth < 5) {
            setTickCount(10);
        } else {
            setTickCount(20);
        }
    };
    /**********  useEffect  **********/
    useEffect(() => {
        if ((columnsMarePrevista.length > 0) && (separatorsMarePrevista.length === (columnsMarePrevista.length - 1))) {
            const lines = textMarePrevista.replace(/\r/g, '').split("\n");

            setHeaderLinesMarePrevista(lines.slice(0, headerEraseMarePrevista).join('\n').split('\n'));
            setDataLines(lines.slice(headerEraseMarePrevista).join('\n').split('\n'));

            console.log("Texto processado");
        }
        else {
            console.log("Erro ao processar texto");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateGraphMarePrevista]);

    useEffect(() => {
        mountDataset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLines]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Extrai a porção de dados a ser exibida
    const visibleData = dataSetMarePrevista?.slice(visibleRange.start, visibleRange.end);


    return (
        <div className={styles.container}>
            <div>
                <VictoryChart
                    theme={VictoryTheme.material}
                    width={windowSize.width}
                    height={windowSize.height - 450}
                    containerComponent={<VictoryZoomContainer responsive={true} zoomDimension="x" />}
                    onDomainChange={domainChange}
                    domainPadding={{ x: 10, y: 10 }}
                >
                    <VictoryLine
                        data={visibleData}
                        x="date"
                        y="value"
                        style={{ data: { stroke: 'blue', strokeWidth: 2 } }}
                    />

                    <VictoryAxis
                        dependentAxis
                    />

                    <VictoryAxis
                        independentAxis
                        tickCount={tickCount}
                        style={{
                            axis: { stroke: 'black' },
                            tickLabels: { fontSize: 10 },
                        }}
                    />
                </VictoryChart>
                
                <div
                    ref={scrollRef}
                    onScroll={handleScroll}
                    style={{ overflowX: 'auto', maxWidth: '100%' }}
                >
                    <div
                        style={{ width: `${dataSetMarePrevista?.length}px`, height: '1px' }} // Largura proporcional ao número de dados
                    />
                </div>
            </div>

        </div>
    )
}

export default GraphicMarePrevista;
import React, { useContext } from "react";
import './styles/app.css';
import styles from "./styles/sharedStyles.module.css";

import StationInformation from "./components/stationInformation";
import Graphic from "./components/graphic";
import Calculator from "./components/calculator";
import Header from "./components/header";
import NavBar from "./components/navBar";
import Footer from "./components/footer";

import { SharedStateContext } from "./contexts/SharedStateContext";

function App() {
    const { step } = useContext(SharedStateContext);
    const components = [
        <StationInformation />,
        <Graphic />,
        <Calculator />
    ]; // Sempre que adicionar um componente, atualizar a função nextComponent em SharedStateContext

    return (
        <div className={styles.container_app}>
            <Header />
            <NavBar />
            {components[step]}
            <Footer />
        </div>
    );
}

export default App;

export const lightTheme = {
    '--bg-color': '#f0f8ff',
    '--text-color': '#4a1c40',
    '--border-color': '#b088f9',
    '--bg-button-color': '#025299',
    '--text-button-color': '#fff',
    '--bg-color-navbar': '#02529980',
};

export const darkTheme = {
    '--bg-color': '#333',
    '--text-color': '#eee',
    '--border-color': '#444',
};
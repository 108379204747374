import React from "react"
import styles from "../styles/sharedStyles.module.css"

function ConstantFile({ setBody }) {

    // Carregar os dados
    const FileUpload = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const text_ = await file.text();

            const lines = text_.split('\n');
            
            const relevantLines = lines.slice(6);

            const d = {};
                
            relevantLines.forEach(line => {
                const parts = line.trim().split(/\s+/);

                if (parts.length >= 3) {
                    const key = parts[0].replace(/['"]+/g, '');
                    
                    const value1 = parseFloat(parts[parts.length - 2].replace(',', '.'));
                    const value2 = parseFloat(parts[parts.length - 1].replace(',', '.'));
                    
                    d[key] = [value1, value2];
                }
            });

            setBody({
                "NMML": 0,
                "HO1": d?.O1?.[0],
                "HK1": d?.K1?.[0],
                "HM2": d?.M2?.[0],
                "HS2": d?.S2?.[0],
                "HP1": d?.P1?.[0],
                "HN2": d?.N2?.[0],
                "HK2": d?.K2?.[0],
                "GO1": d?.O1?.[1],
                "GK1": d?.K1?.[1],
                "GM2": d?.M2?.[1],
                "GS2": d?.S2?.[1],
                "GP1": d?.P1?.[1],
                "GN2": d?.N2?.[1],
                "GK2": d?.K2?.[1]    
            });
        } else {
            setBody({});
        }
    };

    return (
        <input
            name="constFile"
            type="file"
            className={styles.input}
            onChange={FileUpload}
        />
    );
}

export default ConstantFile;
import React, { useState } from "react"
import styles from "../styles/sharedStyles.module.css";

function TextEditor({ text, setText, nextStep }) {
    const [searchWord, setSearchWord] = useState("");
    const [replaceWord, setReplaceWord] = useState("");

    // Carregar os dados
    const FileUpload = async (event) => {
        // Verifica se algum arquivo foi escolhido
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const text_ = await file.text();
            setText(text_);
        } else {
            setText("");
        }
    };

    const handleSearchAndReplace = () => {
        const regex = new RegExp(
            searchWord.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "g");
        const newText = text.replace(regex, replaceWord);
        setText(newText);
    };

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <label className={styles.label}>
                    Arquivo:
                    <input
                        type="file"
                        className={styles.input}
                        onChange={FileUpload}
                    />
                </label>

                <label className={styles.label}>
                    Buscar:
                    <input
                        type="text"
                        className={styles.input}
                        value={searchWord}
                        onChange={(e) => setSearchWord(e.target.value)}
                    />
                </label>

                <label className={styles.label}>
                    Substituir:
                    <input
                        type="text"
                        className={styles.input}
                        value={replaceWord}
                        onChange={(e) => setReplaceWord(e.target.value)}
                    />
                </label>

                <button onClick={handleSearchAndReplace} className={styles.button}>Aplicar Substituição</button>
                {/* <button onClick={nextStep} className={styles.button}>Próximo</button> */}

                <textarea
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className={styles.textarea}
                    rows="30"
                />
            </div>
        </div>
    );
}

export default TextEditor;
import React, { useContext } from "react";

import {
    convertPosicao,
    converteEmHoraDecimal,
    convertData
} from "../helpers/util";

import { SharedStateContext } from "../contexts/SharedStateContext";

function DownloadButton() {

    const {
        dataSet,
        options,
        setOptions
    } = useContext(SharedStateContext);

    // Baixar o arquivo editado
    const downloadDataFile = () => {
        // Será usado posteriormente
        const n_amostras = dataSet.length;
        setOptions({ ...options, 'n_amostras': n_amostras })

        let dataText = "";
        dataText += `1 "${options.nome_estacao}"\r\n`;
        dataText += `${convertPosicao(options.latitude_grau, options.latitude_min, options.latitude_seg, options.latitude)}\r\n`;
        dataText += `${convertPosicao(options.longitude_grau, options.longitude_min, options.longitude_seg, options.longitude)}\r\n`;
        dataText += `${options.fuso} ${converteEmHoraDecimal(options.hora_inicial)} ${convertData(options.data_inicial)} ${converteEmHoraDecimal(options.intervalo)} ${n_amostras} ${options.fator_escala}\r\n`;

        if (parseInt(options.fator_escala) === 1) {
            dataSet.map(item => dataText += `${item.value}\r\n`);
        }
        else {
            dataSet.map(item => dataText += `${parseFloat(item.value).toFixed(3)}\r\n`);
        }

        const blob = new Blob([dataText], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "dados.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <button type="button" onClick={() => downloadDataFile()}>
            Download
        </button>
    )
}

export default DownloadButton;
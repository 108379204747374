import React from "react";
import styles from "../styles/sharedStyles.module.css"

import Logo from "../source/logo.svg";
import DarkModeButton from "../components/darkModeButton";

function Header() {
    return (
        <header className={styles.header_app}>
            <div className={styles.container_header}>
                <img
                    className={styles.img_header}
                    src={Logo}
                    alt="Capa"
                />
                <h1 className={styles.h1_header}>TIDE EDITOR</h1>
            </div>
            <hr className={styles.hr_header} />
            <DarkModeButton />
        </header>
    )
}

export default Header;
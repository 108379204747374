import React, { useState, useEffect } from "react";
import styles from "../styles/sharedStyles.module.css" // estilização css

function ColumnDefinition({
    text,
    generateGraph,
    setGenerateGraph,
    headerErase,
    setHeaderErase,
    columns,
    numColumn,
    setNumColumn,
    setColumns,
    separators,
    setSeparators,
    closeModal
}) {

    const numberSample = 40;

    const [html, setHtml] = useState("");

    const [columnTypes, setColumnTypes] = useState([
        { type: "Data", color: "#FFFF00" },
        { type: "Hora", color: "#32CD32" },
        { type: "Profundidade", color: "#00BFFF" },
    ]);

    const [sample, setSample] = useState("");

    const [selectedOptions, setSelectedOptions] = useState(Array(columns.length).fill(''));

    function handleSetHeaderErase(value) {
        if (value >= 0) {
            setHeaderErase(Number(value));
        }
    }

    function handleSetNumColumn(value) {
        if (value >= 0) {

            let aux_separators = [];
            for (let i = 0; i < value - 1; i++) {
                aux_separators.push(" ")
            }

            let aux_columns = [];
            for (let i = 0; i < value; i++) {
                aux_columns.push(" ")
            }

            setNumColumn(Number(value));
            setColumns(aux_columns);
            setSeparators(aux_separators);
        }
    }

    function handleValueSeparators(value, index) {
        let newSeparators = [...separators];
        newSeparators[index] = value;
        setSeparators(newSeparators);
    }

    function handleValueColumns(value, index) {
        let newColumns = [...columns];
        newColumns[index] = value;
        setColumns(newColumns);
        setSelectedOptions(newColumns);
    }

    function submit() {
        setGenerateGraph(!generateGraph);
        closeModal();
    }

    useEffect(() => {
        let end = 0;
        const numLines = Number(numberSample);
        for (let i = 0; i < numLines; i++) {
            let nextEnd = text.indexOf('\n', end);
            if (nextEnd === -1) {
                setSample(text);
                return;
            }
            end = nextEnd + 1;
        }
        setSample(text.substring(0, end));
    }, [text, numberSample]);

    useEffect(() => {
        const lines = sample.split(/\r?\n/);

        let regex;

        if (separators.length > 0) {
            regex = new RegExp(separators.join('|'), 'g');
        } else {
            regex = new RegExp('$.^'); // Não corresponde a nada, mantendo a string inteira
        }

        const htmlSample = lines.map((line, index) => {
            if (index < headerErase) {
                return `<span style="background-color: #ff9999;">${line}</span><br/>`;
            } else {
                let columns = line.split(regex).filter(Boolean);
                let coloredLine = columns.map((column, columnIndex) => {
                    // Aplicar a cor de fundo correspondente ao tipo de coluna, se houver
                    let columnColor = columnTypes[columnIndex] ? columnTypes[columnIndex].color : "#fff";
                    return `<span style="background-color: ${columnColor};">${column}</span>`;
                }).join(' '); // Use o separador desejado para juntar as colunas coloridas

                return `<div>${coloredLine}</div>`;
            }
        }).join('');

        setHtml(`<div style="font-family: Arial, sans-serif; font-size: 14px; color: #000; background-color: #fff; padding: 1px">${htmlSample}</div>`);
    }, [sample, headerErase, columnTypes, separators, text]);

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <div>
                    <label className={styles.label}>
                        Cabeçalho do arquivo
                        <input
                            type="number"
                            className={styles.input}
                            value={headerErase}
                            onChange={(event) => handleSetHeaderErase(event.target.value)}
                        />
                    </label>
                </div>

                <div>
                    <label className={styles.label}>
                        Quantidade de colunas
                        <input
                            type="number"
                            className={styles.input}
                            value={numColumn}
                            min="1"
                            max="3"
                            onChange={(event) => {
                                const value = Math.max(1, Math.min(Number(event.target.value), 3));
                                handleSetNumColumn(value);
                            }}
                        />
                    </label>
                </div>

                <div className={styles.sub_container_colunmdefinition}>
                    <label className={styles.label}>
                        Separadores
                        <div className={styles.sub_label_colunmdefinition}>
                            {
                                separators.length > 0 && separators.map((value, index) => (
                                    <select
                                        key={index + "sep"}
                                        className={styles.input}
                                        value={value}
                                        onChange={(event) => handleValueSeparators(event.target.value, index)}
                                    >
                                        <option value="">Escolha um separador</option>
                                        <option value=",">Vírgula (,)</option>
                                        <option value=";">Ponto e vírgula (;)</option>
                                        <option value="\t">Tabulação</option>
                                        <option value=" ">Espaço</option>
                                        {/* Adicione mais opções conforme necessário */}
                                    </select>
                                ))
                            }
                        </div>
                    </label>

                    <label className={styles.label}>
                        Colunas
                        <div>
                            {
                                columns.length > 0 && columns.map((value, index) => (
                                    <select
                                        key={index + "col"}
                                        type="text"
                                        className={styles.select}
                                        value={value}
                                        onChange={(event) => handleValueColumns(event.target.value, index)}
                                    >
                                        <option value="">Selecione...</option>
                                        {
                                            columnTypes.filter(columnType => !selectedOptions.includes(columnType.type) || columnType.type === value).map((columnType, columnTypeIndex) => (
                                                <option key={columnTypeIndex} value={columnType.type}>
                                                    {columnType.type}
                                                </option>
                                            ))
                                        }
                                    </select>
                                ))
                            }
                        </div>
                    </label>
                </div>

                <button onClick={() => submit()}>Gerar Gráfico</button>
                
                <div>
                    <div className={styles.mounted} dangerouslySetInnerHTML={{ __html: html }} />
                </div>
            </div>
        </div>
    )
}

export default ColumnDefinition;

import React, { useState, useEffect } from "react";
import styles from "../styles/sharedStyles.module.css"

import { darkTheme, lightTheme } from "../styles/theme";

function DarkModeButton() {
    const [isDarkMode, setIsDarkMode] = useState(
        !localStorage.getItem("theme")
            ? false
            : localStorage.getItem("theme") === "light"
                ? false
                : true
    );

    useEffect(() => {
        const theme = isDarkMode ? darkTheme : lightTheme;

        Object.keys(theme).forEach(key => {
            document.documentElement.style.setProperty(key, theme[key]);
        });

        localStorage.setItem("theme", isDarkMode ? "black" : "light");
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <button className={styles['button-dark-mode-button']} onClick={toggleDarkMode}>
            {isDarkMode ? "Modo Claro" : "Modo Escuro"}
        </button>
    )
}

export default DarkModeButton;
import { createContext, useState } from "react";

export const SharedStateContext = createContext();

export const SharedStateProvider = ({ children }) => {
    const [text, setText] = useState("");
    const [textMarePrevista, setTextMarePrevista] = useState("");
    const [dataSet, setDataSet] = useState([]);
    const [dataSetMarePrevista, setDataSetMarePrevista] = useState([]);
    const [generateGraph, setGenerateGraph] = useState(false);
    const [generateGraphMarePrevista, setGenerateGraphMarePrevista] = useState(false);
    const [headerErase, setHeaderErase] = useState(0);
    const [headerEraseMarePrevista, setHeaderEraseMarePrevista] = useState(0);
    const [columns, setColumns] = useState(['Profundidade']);
    const [columnsMarePrevista, setColumnsMarePrevista] = useState(['Profundidade']);
    const [numColumn, setNumColumn] = useState(1);
    const [numColumnMarePrevista, setNumColumnMarePrevista] = useState(1);
    const [separators, setSeparators] = useState([]);
    const [separatorsMarePrevista, setSeparatorsMarePrevista] = useState([]);
    const [options, setOptions] = useState({
        "nome_estacao": "",
        "latitude_grau": 0,
        "latitude_min": 0,
        "latitude_seg": 0.0,
        "latitude": "N",
        "longitude_grau": 0,
        "longitude_min": 0,
        "longitude_seg": 0.0,
        "longitude": "E",
        "fuso": 0,
        "hora_inicial": "00:00:00",
        "data_inicial": "2000-01-01",
        "intervalo": "00:00:00",
        "n_amostras": 0,
        "fator_escala": 1
    });
    const [step, setStep] = useState(0);
    const [headerLines, setHeaderLines] = useState([]);
    const [headerLinesMarePrevista, setHeaderLinesMarePrevista] = useState([]);
    const [showMarePrevista, setShowMarePrevista] = useState(false);

    const nextComponent = () => {
        setStep((prevStep) => (prevStep + 1) % 3); // 3 é o número de componentes
    };

    const value = {
        text,
        setText,
        dataSet,
        setDataSet,
        generateGraph,
        setGenerateGraph,
        headerErase,
        setHeaderErase,
        columns,
        setColumns,
        numColumn,
        setNumColumn,
        separators,
        setSeparators,
        options,
        setOptions,
        step,
        setStep,
        nextComponent,
        headerLines,
        setHeaderLines,
        textMarePrevista,
        setTextMarePrevista,
        generateGraphMarePrevista,
        setGenerateGraphMarePrevista,
        headerEraseMarePrevista,
        setHeaderEraseMarePrevista,
        columnsMarePrevista,
        setColumnsMarePrevista,
        numColumnMarePrevista,
        setNumColumnMarePrevista,
        separatorsMarePrevista,
        setSeparatorsMarePrevista,
        dataSetMarePrevista,
        setDataSetMarePrevista,
        headerLinesMarePrevista,
        setHeaderLinesMarePrevista,
        showMarePrevista, 
        setShowMarePrevista
    }

    return <SharedStateContext.Provider value={value}>{children}</SharedStateContext.Provider>
}
import React, { useContext } from "react";
import styles from "../styles/sharedStyles.module.css";

import { SharedStateContext } from "../contexts/SharedStateContext";

function StationInformation() {
    const { options, setOptions, nextComponent } = useContext(SharedStateContext);

    function handleChange(event) {
        const { name, value } = event.target;

        if (name === 'fuso') {
            if (value === '' || (value >= -12 && value <= 12)) {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [name]: value,
                }));
            }
        } else {
            setOptions((prevOptions) => ({
                ...prevOptions,
                [name]: value,
            }));
        }
    };

    const handleKeyDown = (event) => {
        const value = event.target.value;
        const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab"];

        if (
            !/^[0-9]$/.test(event.key) &&
            !allowedKeys.includes(event.key) &&
            !(event.key === "-" && value === "")
        ) {
            event.preventDefault();
        }
    };

    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            <div className={styles.form}>
                <label className={styles.label}>
                    Nome da Estação:
                    <input
                        className={styles.name}
                        name="nome_estacao"
                        value={options.nome_estacao}
                        required
                        type="text"
                        onChange={(handleChange)}
                    />
                </label>

                <label className={styles.label}>
                    Latitude:
                    <div>
                        <input
                            name="latitude_grau"
                            value={options.latitude_grau}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" º"}

                        <input
                            name="latitude_min"
                            value={options.latitude_min}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" '"}

                        <input
                            name="latitude_seg"
                            value={options.latitude_seg}
                            className={styles.input}
                            required
                            type="number"
                            onChange={handleChange}
                        />{' "'}

                        <select
                            name="latitude"
                            value={options.latitude}
                            className={styles.select}
                            required
                            onChange={handleChange}
                        >
                            <option value="N">N</option>
                            <option value="S">S</option>
                        </select>
                    </div>
                </label>

                <label className={styles.label}>
                    Longitude:
                    <div>
                        <input
                            name="longitude_grau"
                            value={options.longitude_grau}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" º"}

                        <input
                            name="longitude_min"
                            value={options.longitude_min}
                            className={styles.input}
                            required
                            type="number"
                            step="1"
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />{" '"}

                        <input
                            name="longitude_seg"
                            value={options.longitude_seg}
                            className={styles.input}
                            required
                            type="number"
                            onChange={handleChange}
                        />{' "'}

                        <select
                            name="longitude"
                            value={options.longitude}
                            className={styles.select}
                            required
                            onChange={handleChange}
                        >
                            <option value="E">E</option>
                            <option value="W">W</option>
                        </select>
                    </div>
                </label>

                <label className={styles.label}>
                    Fuso:
                    <input
                        name="fuso"
                        value={options.fuso}
                        className={styles.input}
                        required
                        type="number"
                        step="1"
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                </label>

                <label className={styles.label}>
                    Hora Inicial:
                    <input
                        name="hora_inicial"
                        value={options.hora_inicial}
                        className={styles.input}
                        required
                        type="time"
                        step="1"
                        onChange={handleChange}
                    />
                </label>

                <label className={styles.label}>
                    Data Inicial:
                    <input
                        name="data_inicial"
                        value={options.data_inicial}
                        className={styles.input}
                        type="date"
                        required
                        onChange={handleChange}
                    />
                </label>

                <label className={styles.label}>
                    Intervalo de tempo entre as coletas da altura de maré:
                    <input
                        name="intervalo"
                        value={options.intervalo}
                        className={styles.input}
                        required
                        type="time"
                        step="1"
                        onChange={handleChange}
                    />
                </label>

                <label className={styles.label}>
                    Fator de Escala:
                    <select
                        name="fator_escala"
                        value={options.fator_escala}
                        className={styles.select}
                        required
                        onChange={handleChange}
                    >
                        <option value="1">centímetros</option>
                        <option value="100">metros</option>
                    </select>
                </label>

                <button type="submit" className={styles.button} onClick={() => nextComponent()}>Continuar</button>
            </div>
        </div>
    )
}

export default StationInformation;

/**
 * Função para converter um intervalo HH:MM:SS em milissegundos
 * 
 * @param {string} interval Um intervalor em intervalo HH:MM:SS
 * @returns {number} - O numero de milissegundos correspondente
 */
export function parseInterval(interval) {
    const parts = interval.split(':').map(Number); // Converte cada parte em número
    return (parts[0] * 3600000) + (parts[1] * 60000) + (parts[2] * 1000);
}

/**
 * <KARINE> Adicione uma descrição
 * 
 * @param {string} grau <KARINE> Adicione uma descrição
 * @param {string} minuto <KARINE> Adicione uma descrição
 * @param {string} segundo <KARINE> Adicione uma descrição
 * @param {string} pos <KARINE> Adicione uma descrição
 * @returns {string} <KARINE> Adicione uma descrição
 */
export function convertPosicao(grau, minuto, segundo, pos) {
    grau = grau.toString();
    minuto = parseInt(minuto);
    segundo = parseInt(segundo);
    
    let posicao = "";

    posicao += `${grau.padStart(2, '0')} `;

    let [inteiro, decimal] = (minuto + segundo/60).toFixed(1).toString().split('.');
    posicao += `${inteiro.padStart(2, '0')}.${decimal}`;
    
    posicao += ` "${pos}"`

    return posicao;
}

/**
 * Converte em hora no formato HH:MM:SS em hora decimal.
 * 
 * @param {string} horaInicial - hora no formato HH:MM:SS
 * @returns {number} número correspondente a hora informada.
 */
export function converteEmHoraDecimal(horaInicial) {
    // hora, minuto, segundo
    const valor = horaInicial.split(":");
    const hora = parseInt(valor[0], 10);
    const minuto = parseInt(valor[1], 10);
    const segundo = parseInt(valor[2], 10);

    return (hora + minuto/60 + segundo/3600).toFixed(4);
}

/**
 * Converte uma data de 'YY-MM-DD' para 'DD MM YY'
 * 
 * @param {string} data Uma data no formato YY-MM-DD
 * @returns {string} Uma string no formato DD MM YY
 */
export function convertData(data) {
    const valor = data.split("-");
    const ano = valor[0];
    const mes = valor[1];
    const dia = valor[2];

    return `${dia} ${mes} ${ano}`;

}
import React, {
    useState,
    useEffect,
    useContext,
    useRef
} from "react";

import styles from "../styles/sharedStyles.module.css"

import {
    VictoryChart,
    VictoryLine,
    VictoryScatter,
    VictoryZoomContainer,
    VictoryTheme,
    VictoryAxis
} from 'victory';

import Modal from "react-modal";

import { SharedStateContext } from "../contexts/SharedStateContext";

import { parseInterval } from "../helpers/util";

import DownloadButton from "./downloadButton";
import TextEditor from "./textEditor";
import ColumnDefinition from "./columndefinition";
import GraphicMarePrevista from "./graphicMarePrevista";

function Graphic() {

    Modal.setAppElement('#root');

    const [modalGerarMaregraficoIsOpen, setModalGerarMaregraficoIsOpen] = useState(false);
    function openModalGerarMaregrafico() { setModalGerarMaregraficoIsOpen(true); }
    function closeModalGerarMaregrafico() { setModalGerarMaregraficoIsOpen(false); }

    const [modalGerarMarePrevistaIsOpen, setModalGerarMarePrevistaIsOpen] = useState(false);
    function openModalGerarMarePrevista() { setModalGerarMarePrevistaIsOpen(true); }
    function closeModalGerarMarePrevista() { setModalGerarMarePrevistaIsOpen(false); }

    /********** Constantes **********/
    const CORRECT_POINT = "green";
    const INCORRECT_POINT = "red";

    /********** useState **********/
    const [selectedPoint, setSelectedPoint] = useState({});
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [tickCount, setTickCount] = useState(5);
    const [visibleRange, setVisibleRange] = useState({ start: 0, end: 500 });
    const [editValue, setEditValue] = useState();
    const [pointsToBeDeleted, setPointsToBeDeleted] = useState([]);
    const [dataLines, setDataLines] = useState([]);
    const [stepMaregrafico, setStepMaregrafico] = useState(0);
    const [stepMarePrevista, setStepMarePrevista] = useState(0);

    /********** useContext **********/
    const {
        dataSet,
        setDataSet,
        text,
        setText,
        generateGraph,
        headerErase,
        columns,
        separators,
        options,
        setHeaderLines,
        nextComponent,
        setGenerateGraph,
        setHeaderErase,
        numColumn,
        setNumColumn,
        setColumns,
        setSeparators,
        textMarePrevista,
        setTextMarePrevista,
        generateGraphMarePrevista,
        setGenerateGraphMarePrevista,
        headerEraseMarePrevista,
        setHeaderEraseMarePrevista,
        columnsMarePrevista,
        setColumnsMarePrevista,
        numColumnMarePrevista,
        setNumColumnMarePrevista,
        separatorsMarePrevista,
        setSeparatorsMarePrevista,
        showMarePrevista, 
        setShowMarePrevista
    } = useContext(SharedStateContext);

    /********** useRef **********/
    const scrollRef = useRef(null);

    /********** Funções **********/
    const mountDataset = () => {
        const dataSetAux = [];
        let maxValueAux = 0;

        let regex;

        if (separators.length > 0) {
            regex = new RegExp(separators.join('|'), 'g');
        } else {
            regex = /\s|\n|\r/g;
        }

        let currentDate = null;
        if (options && options.data_inicial && options.hora_inicial) {
            currentDate = new Date(`${options.data_inicial}T${options.hora_inicial}`);
        } else {
            currentDate = new Date();
        }

        for (let i = 0; i < dataLines.length; i++) {
            let result = dataLines[i].split(regex).filter(Boolean);

            if (result.length >= columns.length) {
                let date = '';
                let value = '';

                for (let j = 0; j < columns.length; j++) {
                    if (columns[j] === 'Profundidade') {
                        value = parseFloat(result[j]);
                    } else {
                        date += result[j] + ' ';
                    }
                }

                if (columns.length === 1 && columns[0] === 'Profundidade') {
                    for (let j = 0; j < result.length; j++) {
                        let value = parseFloat(result[j]);
                        let date = new Date(currentDate).toLocaleString();

                        const d = { index: i, date, value };

                        if (maxValueAux < d.value) {
                            maxValueAux = d.value;
                        }

                        dataSetAux.push(d);

                        if (options && options.intervalo) {
                            currentDate.setTime(currentDate.getTime() + parseInterval(options.intervalo));
                        } else {
                            currentDate.setTime(currentDate.getTime() + parseInterval("01:00:00"));
                        }
                    }
                }
                else {
                    const d = { index: i, date, value };

                    if (maxValueAux < d.value) {
                        maxValueAux = d.value;
                    }

                    dataSetAux.push(d);
                }
            }
        }

        setDataSet(dataSetAux);

    }

    const handleScroll = () => {
        const scrollPosition = scrollRef.current.scrollLeft;
        const maxScroll = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
        const dataLength = dataSet.length;
        const visibleDataCount = 500;

        // Calcula o índice de início de forma completamente fluida
        let start = Math.floor((scrollPosition / maxScroll) * (dataLength - visibleDataCount));
        if (start > dataLength - visibleDataCount) {
            start = dataLength - visibleDataCount; // Garante que não ultrapasse o limite dos dados
        }
        let end = start + visibleDataCount;

        setVisibleRange({ start, end });
    };

    // Adiciona pontos na lista para serem apagados
    const addPointDelete = (index) => {
        setPointsToBeDeleted([...pointsToBeDeleted, index]);
    };

    // Remove pontos da lista para serem apagados
    const removePointDelete = (index) => {
        setPointsToBeDeleted(pointsToBeDeleted.filter((item) => item !== index));
    };

    // Apaga os pontos da lista
    const applyPointsDelete = () => {
        setDataSet(
            dataSet.filter((item) => !pointsToBeDeleted.includes(item.index))
        );
        setPointsToBeDeleted([]);
    };

    const domainChange = (domain) => {
        //verifica a largura do domínio do eixo x
        const [xMin, xMax] = domain.x;
        const xWidth = Math.abs(xMax - xMin);

        // Atualiza o número de ticks do eixo x com base na largura do domínio
        if (xWidth < 2) {
            setTickCount(5);
        } else if (xWidth < 5) {
            setTickCount(10);
        } else {
            setTickCount(20);
        }
    };

    const changePointValue = (event) => {
        const newValue = event.target.value;

        if (newValue === "") {
            setEditValue("");
            return;
        }

        const parsedValue = parseFloat(newValue);

        if (!isNaN(parsedValue)) {
            setEditValue(parsedValue);

            const updatedDataSet = dataSet.map((point) => {
                if (point.index === selectedPoint.index) {
                    return { ...point, value: parsedValue };
                }
                return point;
            });

            setDataSet(updatedDataSet);
        }
    }

    const nextStepMaregrafico = () => {
        if (stepMaregrafico === 0) {
            setStepMaregrafico(1)
        }
        else if (stepMaregrafico === 1) {
            setStepMaregrafico(0);
        }
    }

    const nextStepMarePrevista = () => {
        if (stepMarePrevista === 0) {
            setStepMarePrevista(1)
        }
        else if (stepMarePrevista === 1) {
            setStepMarePrevista(0);
        }

        setShowMarePrevista(true);
    }

    /**********  useEffect  **********/
    useEffect(() => {
        if ((columns.length > 0) && (separators.length === (columns.length - 1))) {
            const lines = text.replace(/\r/g, '').split("\n");

            setHeaderLines(lines.slice(0, headerErase).join('\n').split('\n'));
            setDataLines(lines.slice(headerErase).join('\n').split('\n'));

            console.log("Texto processado");
        }
        else {
            console.log("Erro ao processar texto");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateGraph]);

    useEffect(() => {
        mountDataset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLines]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Extrai a porção de dados a ser exibida
    const visibleData = dataSet?.slice(visibleRange.start, visibleRange.end);

    return (
        <div className={`${styles.container} ${styles.main_app}`}>
            <div className={styles.menu_graphic}>
                <button onClick={openModalGerarMaregrafico}>
                    Gerar Maregrama
                </button>
                <Modal
                    isOpen={modalGerarMaregraficoIsOpen}
                    onRequestClose={closeModalGerarMaregrafico}
                    contentLabel="Example Modal"
                >
                    <div className={styles.div_modal}>
                        <div className={styles.div_button_modal}>
                            <button
                                className={styles.button_modal}
                                onClick={nextStepMaregrafico}
                            >
                                {stepMaregrafico === 0 ? "Próximo" : "Voltar"}
                            </button>

                            <button onClick={closeModalGerarMaregrafico}>X</button>
                        </div>
                        {
                            stepMaregrafico === 0 &&
                            <TextEditor
                                text={text}
                                setText={setText}
                                nextStep={nextComponent}
                            />
                        }
                        {
                            stepMaregrafico === 1 &&
                            <ColumnDefinition
                                text={text}
                                setText={setText}
                                generateGraph={generateGraph}
                                setGenerateGraph={setGenerateGraph}
                                headerErase={headerErase}
                                setHeaderErase={setHeaderErase}
                                columns={columns}
                                numColumn={numColumn}
                                setNumColumn={setNumColumn}
                                setColumns={setColumns}
                                separators={separators}
                                setSeparators={setSeparators}
                                closeModal={closeModalGerarMaregrafico}
                            />
                        }


                    </div>
                </Modal>
            </div>

            <div className={styles.header_graphic}>
                <div className={styles.subheader_graphic}>
                    <label className={styles.label}>
                        Ponto
                        <input
                            className={styles.input}
                            type="text"
                            value={selectedPoint?.index}
                            readOnly
                        />
                    </label>
                    <label className={styles.label}>
                        Data:
                        <input
                            className={styles.input}
                            type="text"
                            value={selectedPoint?.date}
                            readOnly
                        />
                    </label>
                    <label className={styles.label}>
                        Valor:
                        <input
                            className={styles.input}
                            type="number"
                            value={editValue}
                            onChange={(event) => changePointValue(event)}
                        />
                    </label>
                </div>
                <div className={styles.subheader_graphic}>
                    <button onClick={applyPointsDelete}>
                        Apagar pontos selecionados
                    </button>
                    <DownloadButton />
                </div>
            </div>

            <div className={styles["victorychart-graphic"]}>
                <VictoryChart
                    theme={VictoryTheme.material}
                    width={windowSize.width}
                    height={windowSize.height - 450}
                    containerComponent={<VictoryZoomContainer responsive={true} zoomDimension="x" />}
                    onDomainChange={domainChange}
                    domainPadding={{ x: 10, y: 10 }}
                >
                    <VictoryLine
                        data={visibleData}
                        x="date"
                        y="value"
                        style={{ data: { stroke: 'blue', strokeWidth: 2 } }}
                    />

                    <VictoryAxis
                        dependentAxis
                    />

                    <VictoryAxis
                        independentAxis
                        tickCount={tickCount}
                        style={{
                            axis: { stroke: 'black' },
                            tickLabels: { fontSize: 10 },
                        }}
                    />

                    <VictoryScatter
                        data={visibleData}
                        x="date"
                        y="value"
                        size={4}
                        style={{
                            data: { fill: CORRECT_POINT, stroke: 'white', strokeWidth: 2 },
                        }}
                        eventKey={(event) => `${event.index},${event.value}`}
                        events={[
                            {
                                target: 'data',
                                eventHandlers: {
                                    onClick: () => [
                                        {
                                            target: 'data',
                                            mutation: (p) => {
                                                const fill = p.style && p.style.fill;
                                                const point = p.data[p.index];
                                                const value = {
                                                    index: point.index,
                                                    date: point.date,
                                                    value: point.value
                                                }

                                                setSelectedPoint(value);
                                                setEditValue(value.value);


                                                if (fill === CORRECT_POINT) {
                                                    addPointDelete(value.index);
                                                } else if (fill === INCORRECT_POINT) {
                                                    removePointDelete(value.index);
                                                }

                                                return fill === CORRECT_POINT
                                                    ? { style: { fill: INCORRECT_POINT } }
                                                    : { style: { fill: CORRECT_POINT } };
                                            },
                                        },
                                    ],
                                },
                            },
                        ]}
                    />

                </VictoryChart>
                <div
                    ref={scrollRef}
                    onScroll={handleScroll}
                    style={{ overflowX: 'auto', maxWidth: '100%' }}
                >
                    <div
                        style={{ width: `${dataSet.length}px`, height: '1px' }} // Largura proporcional ao número de dados
                    />
                </div>
            </div>

            {showMarePrevista && <GraphicMarePrevista />}

            <button onClick={openModalGerarMarePrevista}>
                Gerar Gráfico de Maré Prevista
            </button>
            <Modal
                isOpen={modalGerarMarePrevistaIsOpen}
                onRequestClose={closeModalGerarMarePrevista}
                contentLabel="Example Modal"
            >
                <div className={styles.div_modal}>
                    <div className={styles.div_button_modal}>
                        <button
                            className={styles.button_modal}
                            onClick={nextStepMarePrevista}
                        >
                            {stepMarePrevista === 0 ? "Próximo" : "Voltar"}
                        </button>

                        <button onClick={closeModalGerarMarePrevista}>X</button>
                    </div>

                    {
                        stepMarePrevista === 0 &&
                        <TextEditor
                            text={textMarePrevista}
                            setText={setTextMarePrevista}
                            nextStep={nextComponent}
                        />
                    }
                    {
                        stepMarePrevista === 1 &&
                        <ColumnDefinition
                            text={textMarePrevista}
                            setText={setTextMarePrevista}
                            generateGraph={generateGraphMarePrevista}
                            setGenerateGraph={setGenerateGraphMarePrevista}
                            headerErase={headerEraseMarePrevista}
                            setHeaderErase={setHeaderEraseMarePrevista}
                            columns={columnsMarePrevista}
                            numColumn={numColumnMarePrevista}
                            setNumColumn={setNumColumnMarePrevista}
                            setColumns={setColumnsMarePrevista}
                            separators={separatorsMarePrevista}
                            setSeparators={setSeparatorsMarePrevista}
                            closeModal={closeModalGerarMarePrevista}
                        />
                    }
                </div>
            </Modal>
        </div>
    )
}

export default Graphic;
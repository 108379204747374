import React, { useContext } from "react";

import styles from "../styles/sharedStyles.module.css";

import FileImg from "../source/file.svg";
import SineImg from "../source/sine.svg";
import CalculatorImg from "../source/calculator.svg";
import DownloadsImg from "../source/downloads.svg";

import { SharedStateContext } from "../contexts/SharedStateContext";

function NavBar() {

    const { step, setStep } = useContext(SharedStateContext);

    // Função para determinar se o elemento deve ser destacado
    const isElevated = (elem) => {
        if (elem === "FileImg" && step === 0) {
            return true;
        }
        else if (elem === "SineImg" && step === 1) {
            return true;
        }
        else if (elem === "CalculatorImg" && step === 2) {
            return true;
        }
        else if (elem === "DownloadsImg" && step === 3) {
            return true;
        }
    };

    return (
        <nav className={`${styles.container}, ${styles.navbar_app}`}>
            <ul className={styles.ul_navbar}>
                <li className={`${styles.li_navbar} ${isElevated("FileImg") ? styles["elevated"] : ""}`}>
                    <button
                        className={styles.button_navbar}
                        onClick={() => setStep(0)}
                    >
                        <img
                            className={styles.img_navbar}
                            src={FileImg}
                            alt="FileImg"
                        />
                    </button>
                </li>
                <li className={`${styles.li_navbar} ${isElevated("SineImg") ? styles["elevated"] : ""}`}>
                    <button
                        className={styles.button_navbar}
                        onClick={() => setStep(1)}
                    >
                        <img
                            className={styles.img_navbar}
                            src={SineImg}
                            alt="SineImg"
                        />
                    </button>
                </li>
                <li className={`${styles.li_navbar} ${isElevated("CalculatorImg") ? styles["elevated"] : ""}`}>
                    <button
                        className={styles.button_navbar}
                        onClick={() => setStep(2)}
                    >
                        <img
                            className={styles.img_navbar}
                            src={CalculatorImg}
                            alt="CalculatorImg"
                        />
                    </button>
                </li>
                <li className={`${styles.li_navbar} ${isElevated("DownloadsImg") ? styles["elevated"] : ""}`}>
                    <button
                        className={styles.button_navbar}
                        onClick={() => { }}
                    >
                        <img
                            className={styles.img_navbar}
                            src={DownloadsImg}
                            alt="DownloadsImg"
                        />
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar;